* {
    box-sizing: border-box;
}

html,
body,
.merncms {
    height: 100%;
    margin: 0;
}

.merncms {
    width: 100%;
}

.merncms-main-body {
    background-color: #fafafa;
    display: flex;
    position: relative;
    width: 100%;
}

.merncms-main-body .main > div {
  padding: 40px;
  position: relative;
}
.upload-zone {
    min-height: 200px;
    position: relative;
}
.upload-zone.hover::before {
    content: '';
    position: absolute;
    z-index: 2;
    left: 10px;
    right: 10px;
    top:10px;
    bottom: 10px;
    background: rgba(255,255,255,0.8);
    border: dashed 2px red;
}
.upload-area {
    padding: 15px;
    display: flex;
    min-height: 200px;
    justify-content: center;
    align-items: center;
}
.visible-hide {
    visibility: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset;
}

footer {
    height: 56px;
}
a[href^='https://www.froala.com/wysiwyg-editor?k=u'] {
    opacity: 0;
    position: absolute;
    z-index: -1;
}
.hide {
    display: none!important;
}

.table-responsive td,
.table-responsive th {
    padding: 0 15px;
}

.table-responsive .sort-cell {
    width: 20px;
    padding: 0;
    color: #ccc;
}

.table-responsive .sort-cell:hover {
    cursor: move;
}

.react-calendar.fluid-calendar {
    width: 100%;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.2rem;
    max-width: 1000px;
}

.react-calendar.fluid-calendar button {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    font-weight: 300;
    padding: calc(1/28*100%) 0;
    height: 0;
    line-height: 0;
    position: relative;
}

.react-calendar.fluid-calendar .react-calendar__navigation__arrow svg{
    margin-top: -12px;
}

.fluid-calendar .react-calendar__navigation {
    height: auto;
    background: #802091;
    color: #fff;
}

.fluid-calendar .react-calendar__tile--active {
    background: #e91e63;
}

.fluid-calendar .react-calendar__navigation button {
    color: #fff;
}

.fluid-calendar .react-calendar__month-view__days__day--neighboringMonth {
    color: #ccc;
}

.fluid-calendar .react-calendar__month-view__days__day--weekend {
    color: #802091;
}

.fluid-calendar button.react-calendar__tile:enabled:hover, 
.fluid-calendar button.react-calendar__tile:enabled:focus {
    background: #eee;
}

.fluid-calendar .react-calendar__tile--active:hover,
.fluid-calendar .react-calendar__tile--active:focus,
.fluid-calendar button.react-calendar__tile--active:enabled:hover, 
.fluid-calendar button.react-calendar__tile--active:enabled:focus {
    background: #ec407a;    
}

.react-calendar.fluid-calendar time + span {
    border-radius: 50%;
    text-align: center;
    position: absolute;
    top: 5px;
    right: 5px;
    border: solid 1px #ccc;
    background: #eee;
    color: #111;
    display: block;
    padding: 10px 0;
    height: 0;
    width: 22px;
    font-size: 80%;
    line-height: 0.2;
}

.fluid-calendar .react-calendar__navigation button:enabled:hover, 
.fluid-calendar .react-calendar__navigation button:enabled:focus {
    background: #c075cc;
}

[draggable] {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
}

.table-responsive td:last-child,
.table-responsive th:last-child {
    padding-right: 15px;
    white-space: nowrap;
}

.table-responsive td:last-child button,
.table-responsive th:last-child button {
    width: 30px;
    height: 30px;
    margin: 0 4px;
}

.table-responsive td>span,
.table-responsive th>span {
    white-space: normal;
}

.table-variants-wrapper {
    overflow-x: auto;
    display: block;
}

.table-variants td:last-child button,
.table-variants th:last-child button {
    width: auto;
    height: auto;
    margin: 0 auto;
    padding: 0 2rem;
}

.table-variants td input {
    max-width: 100px;
}

.select,
.select:hover,
.select:focus {
    outline: none;
    position: relative;
}

.select::after {
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
    border-top: solid 5px rgba(0, 0, 0, 0.42);
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 4px;
}

.menuUl {
    margin: 0;
    padding: 0;
}

.menuUl>li {
    min-height: 48px;
    height: auto;
    padding: 0;
}

.menuUl>li a {
    padding: 12px 16px;
}

.menuUl>li>div {
    flex: 1 1 auto;
}

.menuUl>li a aside {
    flex: 1 1;
}

.suggestion-wrapper {
    position: relative;
}

.suggestion-dropdown-menu {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    list-style: none;
    z-index: 1000;
    background: #fff;
}

.fix-chip {
    width: auto;
    max-width: 100%;
    height: auto!important;
    border-radius: 25px!important;
}

.fix-chip span {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    padding: 6px 12px;
}
.main .form-control-no-bottom {
    margin-bottom: 0;
}
.main .gird-thumb {
    width: 100px;
    vertical-align: middle;
}

.banner-page .banner-item-wrapper{
    padding: 10px 30px 30px;
    margin-bottom: 15px;
    background: #f0f0f0;
    position: relative;
}
.banner-page .banner-item-wrapper button {
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    height: 24px;
}
.product-page-detail .item-image-wrapper,
.service-page-detail .item-image-wrapper,
.album-page-detail .item-image-wrapper,
.course-page-detail .item-image-wrapper{
    position: relative;
    padding: 0 15px;
    margin-bottom: 15px;
}
.product-page-detail .item-image-wrapper:nth-child(odd),
.service-page-detail .item-image-wrapper:nth-child(odd),
.album-page-detail .item-image-wrapper:nth-child(odd),
.course-page-detail .item-image-wrapper:nth-child(odd) {
    background: #fafafa;
}
.product-page-detail .item-image-wrapper button,
.service-page-detail .item-image-wrapper button,
.album-page-detail .item-image-wrapper button,
.course-page-detail .item-image-wrapper button{
    position: absolute;
    right: 0;
    top: 15px;
    z-index: 5;
}
.menu-item-list .menu-item {
    background: #fafafa;
    padding: 0 15px;
    margin: 10px 0;
}
.menu-item-list .menu-item .menu-item {
    padding-right: 0;
}
.menu-item-button {
    text-align: right;
}

.main .flex-right {
    display: flex;
    justify-content: flex-end;
}
.grid-file > div {
    display: flex;
    justify-content: center;
    align-items: center;
}
.grid-file span.fa {
    font-size: 10rem;
}
.grid-file span.fa-file-pdf-o {
    color: red;
}
.grid-file span.fa-file-word-o {
    color: #00a1f1;
}
.grid-file span.fa-file-excel-o {
    color: #7cbb00;
}
.grid-file span.fa-file-powerpoint-o {
    color: #f65314;
}

@keyframes DownFade {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -200px, 0);
                transform: translate3d(0, -200px, 0);
    }
    10% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    90% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, -200px, 0);
                transform: translate3d(0, -200px, 0);
    }
}

@-webkit-keyframes DownFade {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -200px, 0);
    }
    10% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
    }
    90% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, -200px, 0);
    }
}

@media screen and (max-width:767px) {
    .merncms-main-body .main > div {
        padding: 15px;
    }
}

@media screen and (min-width:960px) {
    footer {
        height: 64px;
    }
}
